<template>
  <div>
    <el-form class="form">
      <el-row>
        <el-col :span="6">
          <el-form-item label="评论关键字">
            <el-input
              v-model.trim="formData.content"
              placeholder="请输入评论关键字"
              maxlength="50"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="用户姓名">
            <el-input
              v-model.trim="formData.creatorName"
              placeholder="请输入要检索的用户名"
              maxlength="50"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="课程评分">
            <el-select v-model="formData.score" placeholder="请选择">
              <el-option
                v-for="item in scoreList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" class="wxq_button">
          <el-button class="btn btn_blue" @click="search">搜索</el-button>
          <el-button class="btn btn_light_green" @click="getReviewExport">
            <img
              src="@/assets/img/backStageImg/myCourse/derive-icon.png"
              alt=""
            />导出
          </el-button>
        </el-col>
      </el-row>
    </el-form>
    <div class="table">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column
          prop="creatorName"
          label="用户名"
          align="center"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="content"
          label="评价内容"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="score"
          label="课程评分"
          align="center"
          width="100"
        >
          <template slot-scope="scope"> {{ scope.row.score }}星 </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="评价时间"
          align="center"
          width="200"
        ></el-table-column>
      </el-table>
      <pagination
        v-if="totalCount > 0"
        :pageSize="formData.pageSize"
        :total="totalCount"
        @pageChange="pageVal"
      ></pagination>
    </div>
  </div>
</template>

<script>
import pagination from "@/components/backStageComponent/pagination";
import API from "@/apis/backStageEndAPI/myCourseAPI";
import CommonUtils from "@/utils";

export default {
  name: "reviewData",
  props: ["id"],
  components: {
    pagination,
  },
  data() {
    return {
      formData: {
        itemId: "", //课程id
        content: "", //课程关键字
        creatorName: "", //用户姓名
        score: "", //课程评分
        type: 1, //评价类型：1：课程；2：讲师；3：文章
        page: 1, //页码
        pageSize: 10, //分页大小
      },
      totalCount: 0,
      tableData: [],
      scoreList: [
        { value: 0, label: "0星" },
        { value: 1, label: "1星" },
        { value: 2, label: "2星" },
        { value: 3, label: "3星" },
        { value: 4, label: "4星" },
        { value: 5, label: "5星" },
      ],
    };
  },
  mounted() {
    if (this.id) {
      this.getTableList(this.id);
    }
  },
  methods: {
    getReviewExport() {
      // 导出
      let param = CommonUtils.parseToParams({
        page: this.formData.page,
        pageSize: this.formData.pageSize,
        itemId: this.id, //课程id
        content: "", //课程关键字
        creatorName: "", //用户姓名
        score: "", //课程评分
        type: 1, //评价类型：1：课程；2：讲师；3：文章
      });
      API.getEvaluationExport(param, { responseType: "arraybuffer" }).then(
        async (res) => {
          this.$message.success("导出成功!");
          const blob = new Blob([res], { type: "application/vnd.ms-excel" });
          const fileName = "评论数据"; //下载文件名称
          const elink = document.createElement("a");
          elink.download = fileName;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
        }
      );
    },
    getTableList(id) {
      //获取列表数据
      this.formData.itemId = id;
      API.getEvaluationList(CommonUtils.parseToParams(this.formData)).then(
        (res) => {
          this.tableData = res.dataList;
          this.totalCount = res.rowCount;
        }
      );
    },
    search() {
      //搜索
      this.formData.page = 1;
      this.getTableList(this.id);
    },
    pageVal(val) {
      //分页跳转
      this.formData.page = val;
      this.getTableList(this.id);
    },
  },
};
</script>

<style scoped lang="scss">
.wxq_button {
  text-align: right;
  img {
    width: 24px;
    margin-right: 8px;
    vertical-align: middle;
  }
}
</style>
