<template>
  <div>
    <el-form class="form">
      <el-row>
        <el-col :span="22">
          <!--<el-form-item label="">-->
          <!--<el-radio-group v-model="radio">-->
          <el-radio v-model="radio" label="1" style="margin: 0 10px 20px">观众流水</el-radio>
          <!--<el-radio label="2">观众统计</el-radio>-->
          <!--</el-radio-group>-->
          <!--<span>说明1：仅统计观看时间大于1分钟的记录</span>-->
          <!--<span>说明2：直播课程仅统计观看直播的记录（不含回放）</span>-->
          <!--</el-form-item>-->
        </el-col>
        <el-col :span="2" class="wxq_button">
          <el-button class="btn btn_light_green" @click="getStudyLogExport">
            <img src="@/assets/img/backStageImg/myCourse/derive-icon.png" alt=""/>导出
          </el-button>
        </el-col>
      </el-row>
    </el-form>
    <div class="table">
      <el-table
        :data="tableData"
        style="width: 100%">
        <el-table-column label="头像" align="center" width="80">
          <template slot-scope="scope">
            <img class="wxq_table_img" :src="scope.row.avatar ? scope.row.avatar : defaultAvatar" alt="">
          </template>
        </el-table-column>
        <el-table-column prop="name" label="学员姓名" align="center" width="80"></el-table-column>
        <el-table-column prop="staySec" label="观看时长(s)" align="center">
          <template slot-scope="scope">{{ formatTime(parseInt(scope.row.staySec)) }}</template>
        </el-table-column>
        <el-table-column prop="enterTime" label="进入时间" align="center"></el-table-column>
        <el-table-column prop="leaveTime" label="离开时间" align="center"></el-table-column>
        <el-table-column prop="device" label="观看方式" align="center"></el-table-column>
        <el-table-column prop="ip" label="ip地址" align="center"></el-table-column>
      </el-table>
      <pagination v-if="totalCount > 0" :pageSize="formData.pageSize" :total="totalCount"
                  @pageChange="pageVal"></pagination>
    </div>
  </div>
</template>

<script>
import pagination from "@/components/backStageComponent/pagination";
import API from "@/apis/backStageEndAPI/myCourseAPI";
import CommonUtils from "@/utils";
import {formatTime} from "@assets/js/format";
export default {
  name: "watchTheData",
  props: ['id'],
  components: {
    pagination
  },
  data() {
    return {
      formatTime,//转换视频秒数显示格式
      formData: {
        courseId: '',//课程id
        page: 1,//页码
        pageSize: 10//分页大小
      },
      radio: '1',
      totalCount: 0,
      tableData: [],
      defaultAvatar: require('../../../../assets/img/backStageImg/user-icon.png')
    }
  },
  mounted() {
    if (this.id) {
      this.getTableList(this.id)
    }
  },
  methods: {
    getTableList(id) { //获取列表数据
      this.formData.courseId = id
      API.getStudyLogList(id, CommonUtils.parseToParams(this.formData)).then(res => {
        this.tableData = res.dataList
        this.totalCount = res.rowCount
      })
    },
    getStudyLogExport() {
      this.formData.courseId = this.id
      let params = CommonUtils.parseToParams(this.formData)
      API.getStudyLogExport(this.id,CommonUtils.parseToParams(this.formData), {responseType: 'arraybuffer'}).then(async (res)=> {
        this.$message.success('导出成功!')
        const blob = new Blob([res], {type: "application/vnd.ms-excel"})
        const fileName = '观看数据';//下载文件名称
        const elink = document.createElement('a');
        elink.download = fileName;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      })
    },
    search() { //搜索
      this.formData.page = 1
      this.getTableList(this.id)
    },
    pageVal(val) { //分页跳转
      this.formData.page = val;
      this.getTableList(this.id)
    },
  }
}
</script>

<style scoped lang="scss">
.form {
  ::v-deep .el-form-item__label {
    line-height: 56px;
  }
  span {
    color: #7D8592;
    line-height: 50px;
  }
  span:first-of-type {
    margin: 0 18px;
  }
  .wxq_button {
    text-align: right;
    img {
      width: 24px;
      margin-right: 8px;
      vertical-align: middle;
    }
  }
}
.el-table {
  ::v-deep .el-table__cell {
    padding: 6px;
  }
}
.wxq_table_img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  vertical-align: middle;
}
.el-radio {
  width: 124px;
  height: 40px;
  background: #FFFFFF;
  box-shadow: 0 1px 2px 0 rgba(184, 200, 224, 0.22);
  border-radius: 10px;
  border: 1px solid #D8E0F0;
  line-height: 40px;
  ::v-deep .el-radio__input {
    margin-left: 14px;
    .el-radio__inner {
      width: 16px;
      height: 16px;
    }
  }
  ::v-deep .el-radio__label {
    font-size: 16px;
  }
}
.el-radio:first-child {
  margin-right: 12px;
}
</style>
