<template>
  <div>
    <el-form class="form">
      <el-row>
        <el-col :span="4">
          <el-form-item label="签到签退">
            <el-select v-model="formData.signType" placeholder="请选择" clearable>
              <el-option
                v-for="item in signInOutList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="用户名称">
            <el-input v-model.trim="formData.creatorName" placeholder='请输入要检索的用户名' clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="机构名称">
            <el-input v-model.trim="formData.deptName" placeholder="请输入要检索的机构名" maxlength="50" show-word-limit
                      clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6" class="wxq_button">
          <el-button class="btn btn_blue" @click="search">搜索</el-button>
          <el-button class="btn btn_light_green" @click="getSignExport">
            <img src="@/assets/img/backStageImg/myCourse/derive-icon.png" alt=""/>导出
          </el-button>
        </el-col>
      </el-row>
    </el-form>
    <div class="table">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="creatorName" label="用户名" align="center" width="120"></el-table-column>
        <el-table-column prop="deptName" label="机构名称" align="center" width="350"></el-table-column>
        <el-table-column prop="phone" align="center" label="手机号码"></el-table-column>
        <el-table-column prop="createTime" align="center" label="签到时间"></el-table-column>
        <el-table-column prop="createTime" align="center" label="签到签退">
          <template slot-scope="scope">
            {{ scope.row.signType == 1 ? '签到' : '签退' }}
          </template>
        </el-table-column>
        <el-table-column prop="device" align="center" label="签到设备"></el-table-column>
      </el-table>
      <pagination v-if="totalCount > 0" :pageSize="formData.pageSize" :total="totalCount"
                  @pageChange="pageVal"></pagination>
    </div>
  </div>
</template>

<script>
import API from "@/apis/backStageEndAPI/myCourseAPI";
import pagination from '@/components/backStageComponent/pagination';
import CommonUtils from "@/utils";
export default {
  name: 'signInSignOut',
  props: ['id'],
  components: {
    pagination
  },
  data() {
    return {
      formData: {
        signType: '',//签到类型：1：签到；2：签退
        creatorName: '',//用户名称
        deptName: '',//机构名称
        device: '',//签到设备（PC,Android,iOS,H5）
        itemId: '',//课程id
        itemType: '',//签到对象类型：1：课程；3：考试
        page: 1,//页码
        pageSize: 10//分页大小
      },
      signInOutList: [
        {value: 1, label: '签到'},
        {value: 2, label: '签退'},
      ],
      totalCount: 0,
      tableData: []
    };
  },
  mounted() {
    if (this.id) {
      this.getTableList(this.id)
    }
  },
  methods: {
    getTableList(id) { //获取列表数据
      this.formData.itemId = id
      API.getSignList(CommonUtils.parseToParams(this.formData)).then(res => {
        this.tableData = res.dataList
        this.totalCount = res.rowCount
      })
    },
    getSignExport() {
      let params = CommonUtils.parseToParams({
        itemId: this.id,
        page: this.formData.page,
        pageSize: this.formData.pageSize
      })
      var url = `/sign-record.xlsx`;
      API.getSignExport(url, params, {responseType: 'arraybuffer'}).then(async (res) => {
        this.$message.success('导出成功!')
        const blob = new Blob([res], {type: "application/vnd.ms-excel"})
        const fileName = '签到签退数据';//下载文件名称
        const elink = document.createElement('a');
        elink.download = fileName;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      })
    },
    search() { //搜索
      this.formData.page = 1
      this.getTableList(this.id)
    },
    pageVal(val) { //分页跳转
      this.formData.page = val;
      this.getTableList(this.id)
    }
  }
};
</script>

<style scoped lang="scss">
.wxq_button {
  text-align: right;
  img {
    width: 24px;
    margin-right: 8px;
    vertical-align: middle;
  }
}
</style>
