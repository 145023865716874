<template>
  <div class="container">
    <header-bar></header-bar>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/myCourses' }"><i class="el-icon-back"></i>返回</el-breadcrumb-item>
      <el-breadcrumb-item>数据统计</el-breadcrumb-item>
    </el-breadcrumb>
    <h1>{{ data.courseTitle }}</h1>
    <div class="tabs">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="签到签退" name="first">
          <sign-in-sign-out v-if="data" :id="data.courseId"></sign-in-sign-out>
        </el-tab-pane>
        <el-tab-pane label="评论数据" name="second">
          <review-data v-if="data" :id="data.courseId"></review-data>
        </el-tab-pane>
        <el-tab-pane label="观看数据" name="third">
          <watch-the-data v-if="data" :id="data.courseId"></watch-the-data>
        </el-tab-pane>
        <!-- <el-tab-pane label="聊天数据" name="fourth" disabled>聊天数据</el-tab-pane> -->
      </el-tabs>
    </div>
  </div>
</template>

<script>
import headerBar from "@/components/backStageComponent/layout/headerBar/headerBar";
import signInSignOut from "./component/signInSignOut";
import reviewData from "./component/reviewData";
import watchTheData from "./component/watchTheData";
export default {
  name: "dataStatistics",
  components: {
    headerBar,
    signInSignOut,
    reviewData,
    watchTheData
  },
  data() {
    return {
      data: '',
      title: '2020年第三届中国医师节系列活动',
      activeName: 'first',
    }
  },
  mounted() {
    this.data = JSON.parse(this.$route.query.data)
    this.tabLocal()
  },
  methods: {
    tabLocal() { //获取本地tab保证刷新页面不返回第一个tab
      if (sessionStorage.getItem('task') != null) {
        this.activeName = sessionStorage.getItem('task')
      }
    },
    handleClick(tab) { //点击tab存储在本地
      sessionStorage.setItem('task', tab.name)
    }
  },
  destroyed() {
    sessionStorage.removeItem('task')
  }
}
</script>

<style scoped lang="scss">
.container {
	.wxq_header {
		margin-bottom: 0;
	}
	h1 {
		margin: 16px 0 16px 6px;
		color: #0a1629;
		font-weight: 800;
		font-size: 24px;
	}
	.el-breadcrumb__item {
		font-size: 16px;
		::v-deep .is-link {
			color: var(--colorBlue);
			.el-icon-back {
				margin-right: 12px;
			}
		}
	}
}

</style>
